








































































import {vxm} from "@/store";
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  CaseApiFactory,
  CaseDTO,
  CaseFilterDTO,
  CaseStatus,
  CompanyDTO,
  Configuration, ErpcDTO, ShortUserDTO
} from "@shared_vue/openapi/caseapi";
import 'firebase/auth';

import {ServiceBusClient, ServiceBusReceivedMessage, ServiceBusReceiver} from "@azure/service-bus";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ErpcAddModal from "@/views/widgets/modals/ErpcAddModal.vue";
import ButtonDateInput from "@/views/viewcomponents/ButtonDateInput.vue"
import axios from 'axios';
import firebase from "firebase";
import {response} from "msw";


@Component({components: { WaitModal, ButtonDateInput, ErpcAddModal } })
export default class ErpcList extends Vue {
  @Prop(String) readonly branchid: string | undefined;
  private ui = vxm.ui;
  private caseStore = vxm.case;
  private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
  private showWait: boolean = false;
  private showAddModal: boolean = false;
  private members: ShortUserDTO[] = [];
  private checkedRows: Array<string> = [];
  private imagePath = process.env.VUE_APP_STATIC_DIR + 'images/icons/';
  
  get branchId() {
    return this.branchid ? parseInt(this.branchid) : undefined;
  }
  
  private fields = [
    {key: 'guid'},
    {key: 'name', label: 'Name', _style: 'width:40px'},
    {key: 'email', label: 'Email', _style: 'min-width:100px;'},
    {key: 'cell', label: 'Cell', _style: 'min-width:100px;'},
    {key: 'state', label: 'Status', _style: 'width:10px'},
    {
      key: 'select',
      _style: 'width:1%',
      sorter: false,
      filter: false
    }
  ];
  
  private async fetchMembers(){
    this.showWait = true;
    try {
      const res = await this.caseApi.caseGetMembersForBranch(this.branchId!);
      this.members = res.data;
    } finally {
      this.showWait = false;
    }
  }

  private async massAction(which: string){
    this.showWait = true;
    try {
      if (this.checkedRows.length === 0) {
        alert('YOU MUST SELECT SOMETHING');
      }
      await this.caseApi.caseBulkUpdateUserStates({
        status: which,
        userIds: this.checkedRows
      });
      await this.fetchMembers();
      this.checkedRows = [];
    } finally {
      this.showWait = false;
    }
  }

  private check (item:any, checked: boolean) { //the whole data row is passed in
    console.log(`${item.guid} is ${checked}`)
    if (checked) {
      // Add the ID to the array if it's checked
      if (!this.checkedRows.includes(item.guid)) {
        this.checkedRows.push(item.guid);
      }
    } else {
      // Remove the ID from the array if it's unchecked
      this.checkedRows = this.checkedRows.filter(rowId => rowId !== item.guid);
    }
  }

  private async onClickCompany(item:any, index:any, column:any, event:any){
    console.log("onclickcompany")
    console.log('column: ' + column)
    if (column==='select') { //don't navigate
      return;
    }
    let id = item.id;
    //note that the flow for "NEW" is different to the others
    this.$router.push(`/dashboard/company/${id}`)
  }

  private getColor(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return '#f02b1d';
      case 'active':
        return '#95eb34';
      default:
        '#FFF'
    }
  };
  
  private addnew(){
    this.showAddModal = true;
  }
  
  private addCompany(company: CompanyDTO){
    console.log('addCompany')
    console.log(company)
  }
  mounted(){
    this.fetchMembers();
  }
}


